import request from '@/utils/request'
//  获取家庭信息 
export function getFamilyInfo(params) {
    return request({
      url: '/app-api/eye/family/info',
      method: 'get',
      params
    })
}
// 修改家庭名称
export function familyNameUpdate(data) {
  return request({
    url: '/app-api/eye/family/rename',
    method: 'post',
    data
  })
}
//  获取成员信息 
export function getMemberInfo(params) {
  return request({
    url: '/app-api/eye/family/member/info',
    method: 'get',
    params
  })
}
// 删除成员
export function delMember(params) {
  return request({
    url: `/app-api/eye/family/delete?memberId=${params.memberId}`,
    method: 'delete',    
  })
}
// 修改成员权限
export function updateMemberRole(data) {
  return request({
    url: '/app-api/eye/family/member/roleChange',
    method: 'post',
    data
  })
}
// 创建家庭成员
export function createMember(data) {
  return request({
    url: '/app-api/eye/family/create/member',
    method: 'post',
    data
  })
}
// 加入家庭
export function joinFamily(data) {
  return request({
    url: '/app-api/eye/family/family/add',
    method: 'post',
    data
  })
}

//  获取备注列表
export function getRemarksList(params) {
  return request({
    url: '/app-api/eye/family/remarkList',
    method: 'get',
    params
  })
}
// 获取分享token
export function getShareToken(data) {
  return request({
    url: '/app-api/eye/family/member/wxShare',
    method: 'post',
    data
  })
}
//  根据分享token获取分享信息
export function getShareInfo(params) {
  return request({
    url: '/app-api/eye/family/member/wxShareInfo',
    method: 'get',
    params
  })
}
//  解散家庭组
export function disbandFamily() {
  return request({
    url: '/app-api/eye/family/family/delete',
    method: 'delete'
  })
}