<template>
  <div class="family-list">
    <!-- 顶部家庭名称 -->
    <van-cell :is-link="familyInfo.curMember.isCreator" class="family-name-cell" @click="handleEditFamilyName">
        <template #title>
            <div class="member-info">
                <div class="member-role">家庭名称</div>
              <div class="member-name">{{ familyInfo.familyName }}</div>
            </div>
          </template>
    </van-cell>
    
    <!-- 编辑家庭名称弹窗 -->
    <van-overlay :show="isEditNameDialogVisible" @click="isEditNameDialogVisible = false">
        <div class="edit-name-dialog" @click.stop>
            <div class="edit-name-dialog__header">
                <span>设置家庭名称</span>
            </div>
            <div class="input-cell">
                <van-field v-model="editingFamilyName" placeholder="请输入家庭名称" />
            </div>
            <div class="edit-name-dialog__footer">
                <button class="edit-name-dialog__cancel" @click="isEditNameDialogVisible = false">取消</button>
                <button :class="{ active: editingFamilyName }" class="edit-name-dialog__confirm" @click="handleFamilyNameSubmit">保存</button>
            </div>
        </div>
    </van-overlay>
    
    <!-- 家庭成员列表 -->
    <div class="member-section">
      <div class="section-title">
        家庭成员 ({{ familyInfo.memberList.length }})
      </div>
      
      <!-- 成员列表 -->
      <van-cell-group :border="false">
        <van-cell
          v-for="member in familyInfo.memberList"
          :key="member.memberId"
          is-link
          @click="toMemberInfo(member.memberId)"
          >
          <template #icon>
            <van-image
              round
              :src="member.avatar"
              class="cell-icon"
            />
          </template>
          <template #title>
            <div class="member-info">
              <div class="member-name">{{ member.name }}</div>
              <div class="member-role">{{ member.isCreator ? '创建者' : member.role }}</div>
            </div>
          </template>
        </van-cell>
        <!-- 邀请家人按钮 -->
        <van-cell @click="showInviteDialog">
            <template #icon>
              <div class="add-icon">
                <van-icon name="plus" size="20" />
              </div>
            </template>
            <template #title>
              <span class="invite-text">邀请家人</span>
            </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- 邀请弹窗 -->
    <van-dialog
      v-model:show="isInviteDialogVisible"
      title="请选择家人权限"
      show-cancel-button
      confirm-button-text="下一步"
      @confirm="handleInviteConfirm"
      :close-on-click-overlay="true"
    >
        <div class="invite-dialog__content">
            <van-cell-group :border="false">
                <van-cell clickable @click="selectedRole = '成员'">
                    <template #title>
                        <div class="role-option">
                            <div class="role-option__content">
                                <div class="role-name">成员</div>
                                <div class="role-desc">可监测孩子的训练结果，可使用分享及兑换功能</div>
                            </div>
                            <van-radio class="role-radio" :name="'成员'" v-model="selectedRole" />
                        </div>
                    </template>
                </van-cell>
                <van-cell v-if="familyInfo.curMember.role === '管理员'" clickable @click="selectedRole = '管理员'">
                    <template #title>
                        <div class="role-option">
                            <div class="role-option__content">
                                <div class="role-name">管理员</div>
                                <div class="role-desc">可使用同屏控制，对其他成员有管理权限等，一个家庭组仅限一名管理员，请慎重转让管理员身份。</div>
                            </div>
                            <van-radio class="role-radio" :name="'管理员'" v-model="selectedRole" />
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
    </van-dialog>
  </div>
</template>

<script>
// 引入需要的 Vant 组件
import { Cell, CellGroup, Image as VanImage, Icon, Dialog, Field, Overlay, Radio } from 'vant'
import { getFamilyInfo, familyNameUpdate } from '@/api/family'

export default {
  name: 'FamilyList',
  components: {
    // 注册组件
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Field.name]: Field,
    [Overlay.name]: Overlay,
    [Radio.name]: Radio
  },
  data() {
    return {
      isEditNameDialogVisible: false,
      editingFamilyName: '',
      isInviteDialogVisible: false,
      selectedRole: '成员',
      familyInfo: {
        curMember: {},
        isCreator: false,
        memberList: []
      }
    }
  },
  methods: {
    initData() {
      getFamilyInfo().then(res => {
        this.familyInfo = res;
        localStorage.setItem('userInfo', JSON.stringify({...res.curMember, familyName: res.familyName}))
      }).finally(() => {
        this.$toast.clear()
      })
    },
    toMemberInfo(memberId ) {
      this.$router.push({
        path: '/family/memberInfo',
        query: {
          memberId
        }
      })
    },
    handleEditFamilyName() {
      if (this.familyInfo.curMember.isCreator) {
        this.isEditNameDialogVisible = true
        this.editingFamilyName = this.familyInfo.familyName
      }
    },
    handleFamilyNameSubmit() {
      if (this.editingFamilyName.trim()) {
        familyNameUpdate({
          familyName: this.editingFamilyName
        }).then(res => {
          this.initData()
          this.isEditNameDialogVisible = false
        }).catch(err => {
          this.$toast.clear()
          this.$toast(err.msg)
        })
      }
    },
    showInviteDialog() {
      this.isInviteDialogVisible = true
      this.selectedRole = '成员'
    },
    handleInviteConfirm() {
      console.log('Selected role:', this.selectedRole)
      this.isInviteDialogVisible = false
      this.$router.push({
        path: '/family/setRemarks',
        query: {
          role: this.selectedRole
        }
      })
    }
  },
  mounted() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,   // 禁止背景点击
      duration: 0,  //展示时间，0为持续展示
      overlay: true,  //是否显示背景遮罩层
    })
    this.initData()
  }
}
</script>

<style lang="less" scoped>
.family-list {  
  .member-section {
    .section-title {
        padding: .42rem .34rem .26rem;  
      color: #3BBDD8;
      font-size: .28rem;
      line-height: .32rem;
      font-weight: 500;
    }
  }

  .member-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: .8rem;
    
    .member-name {
      font-size: .3rem;
      color: #000;
      line-height: .36rem;
    }
    
    .member-role {
      font-size: .26rem;
      color: #7a7a7a;
      margin-top: .06rem;
      line-height: .36rem;
    }
  }
  .cell-icon {
    margin-right: .26rem;
    width: .76rem;
    height: .76rem;
    border-radius: 50%;
  }

  .add-icon {
    width: .76rem;
    height: .76rem;
    border-radius: 50%;
    background: #d9d9d9;
    color: #fff;   
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .26rem;
  }
  .invite-text {
    font-size: .3rem;
    line-height: .36rem;
    font-weight: 400;
    color: #000;
  }

  :deep(.van-cell) {
    align-items: center;
    padding: .22rem .34rem .22rem .58rem;
    &::after {
        border-bottom: 0.02rem solid #e3e3e3;
        display: block;
    }
    .van-cell__right-icon {
      font-size: .32rem;
    }
    .van-cell__value {
      display: flex;
      align-items: center;
    }
    &.family-name-cell {
        padding: .3rem .34rem;
        &::after {
            left: 0;
            right: 0;
        }
        .member-info {
            .member-name {
                margin-top: .14rem;
            } 
            .member-role {
                margin-top: 0;
                font-weight: 800;
            }
        }
    }
  }
  .edit-name-dialog {
    width: 6.72rem;
    background: #F1F1F1;
    border-radius: .22rem;
    box-sizing: border-box;   
    padding: .44rem .34rem .34rem;
    border-radius: .54rem; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   
    &__header {
        padding: 0;
        font-size: .36rem;
        line-height: .42rem;
        color: #313131;
        font-weight: 400;
        text-align: center;
    }
    .input-cell {
        margin: .44rem 0;
        border-radius: .22rem;
        overflow: hidden;   
        border: .01rem solid #848484;
        :deep(.van-cell) {
        padding: .2rem .32rem;
      }
    }   
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__cancel, &__confirm {
        flex: none;
        width: 2.58rem;
        height: .86rem;
        background: #DEDEDE;
        border-radius: .24rem;
        font-size: .36rem;
        line-height: .86rem;
        color: #666666;
        font-weight: 400;
        border: none;
        outline: none;
    }
    &__confirm.active {
      color: #3BBDD8;
    }
  }
  .invite-dialog__content {
    padding: 0.1rem;
    .role-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .role-option__content {
            flex: 1;
        }
        .role-name {
            font-size: .28rem;
            line-height: .32rem;
            color: #333;
            font-weight: 600;
            margin-bottom: .1rem;
        }

        .role-desc {
            font-size: .26rem;
            color: #666;
            line-height: .32rem;
        }
        .role-radio {
            margin-left: .2rem;
            width: .36rem;
            height: .36rem;
        }   
    }
    :deep(.van-cell) {
        padding: 0 0 .2rem 0;
        &::after {
            display: none;
        }
    }
  }
}
</style>
